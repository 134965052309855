import React from 'react';
import MaterialTable from 'material-table';
import { BreadCrumbsV2 } from '../../components';
import MuiAlert from '@material-ui/lab/Alert';

import { useLearnerProfileContainerHook } from './useLearnerProfileContainerHook';
import { Grid, Typography, Box, Snackbar } from '@material-ui/core';
import { Button, Modal } from '@orijinworks/frontend-commons';
import { withStyles } from '@material-ui/styles';
import styles from './learner-profile.style';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import useAddContactModalHook from '../../components/add-contact-modal/useAddContactModalHook';
import AddContactModal from '../../components/add-contact-modal/add-contact-modal.component';
const LearnerProfileContainer = ({ classes, locationId }) => {
  const { items, learnerProfile, tableRef, tableColumns, defaultPageSize, getContactsForLearner, setDataRefresh, snackbarConfig, setSnackbarConfig, toggleVRSFlag, disableActionButtons, showEnableVrsConfirmationModal, setShowEnableVrsConfirmationModal} =
    useLearnerProfileContainerHook();
  const tableColumnsRef = React.useRef(tableColumns);
  const {
    addContactModal,
    handleAddModalClose,
    handleAddModalOpen,
    learners,
    searchLearnerLoading,
    contact,
    handleContactChange,
    handleAddContact,
    learnerListFetched,
    handleAddModalCloseSnackbar,
    addContactSuccessSnackbar,
    showAddContactSnackBar,
    snackbarMessageSnackbar,
    snackbarAutoHideDuration,
    handleSearch,
    getRelationshipTypes
} = useAddContactModalHook(locationId,learnerProfile,setDataRefresh);

  return (
    <>
      {snackbarConfig && (
        <Snackbar 
          open={snackbarConfig.showSnackbar}
          autoHideDuration={3000}
          onClose={() => setSnackbarConfig((prev) => ({ ...prev, showSnackbar: false }))}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setSnackbarConfig((prev) => ({ ...prev, showSnackbar: false }))}
            severity={snackbarConfig.snackbarType}
          >
            {snackbarConfig.message}
          </MuiAlert>
        </Snackbar>
      )}

      <Grid container className={classes.root}> 
      <BreadCrumbsV2 items={items} />

        <Grid item xs={12}>
          <Typography variant="h1" id="learner-profile-heading" className={classes.learnerProfileHeading}>
            {learnerProfile ? learnerProfile.learnerName : 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.learnerProfileSection}>
            <AccountCircle className={classes.learnerProfileIcon} />
            <Grid item xs={10}>
              <Box className={classes.learnerProfileInfoSection}>
                <Grid item xs={12}>
                  <Box className={classes.learnerProfileInfo}>
                    <Grid item xs={5}>
                      <Typography className={classes.learnerProfileInfoHeading} variant="body1">
                        Location
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography id="location" variant="body1">
                        {learnerProfile ? learnerProfile.locationName : 'N/A'}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.learnerProfileInfo}>
                    <Grid item xs={5}>
                      <Typography className={classes.learnerProfileInfoHeading} variant="body1">
                        DOC ID#
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography id="docId" variant="body1">
                        {learnerProfile.docId ? learnerProfile.docId : 'N/A'}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.learnerProfileInfo}>
                    <Grid item xs={5}>
                      <Typography className={classes.learnerProfileInfoHeading} variant="body1">
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography id="status" variant="body1">
                        {learnerProfile?.learnerStatus
                          ? learnerProfile.learnerStatus.charAt(0).toUpperCase() + learnerProfile.learnerStatus.slice(1)
                          : 'N/A'}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.learnerProfileInfo}>
                    <Grid container alignItems="center">
                      <Grid item xs={5}>
                        <Typography className={classes.learnerProfileInfoHeading} variant="body1">
                          Accommodations
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Box className={classes.iconLabelContainer}>
                          {learnerProfile?.personAttributes?.A11Y_VRS_ENABLED === 'true' && (
                            <HearingDisabledIcon id="hearingDisabledIcon" className={classes.accessibilityIcon} />
                          )}
                          <Typography
                            id="accomodationLabel"
                            variant="body1"
                          >
                            {learnerProfile?.personAttributes?.A11Y_VRS_ENABLED === 'true' ? 'Video Relay Service' : 'N/A'}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

              </Box>
            </Grid>
          </Box>
        </Grid>

        <Box display="flex" width="100%" alignItems="center" justifyContent="space-between" marginBottom="10px">
          <Typography variant="h2" id="contacts-heading" className={classes.contactsHeading}>
            Contacts
          </Typography>

          <Box className={classes.contactTableButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              data-id="add-contact-button"
              onClick={handleAddModalOpen}
            >
              Add Contact
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disableElevation
              disabled={disableActionButtons}
              data-id="toggle-vrs-button"
              onClick={() => learnerProfile?.personAttributes?.A11Y_VRS_ENABLED === 'true' ? toggleVRSFlag() : setShowEnableVrsConfirmationModal(true)}
            >
              {learnerProfile?.personAttributes?.A11Y_VRS_ENABLED === 'true' ? 'Disable VRS' : 'Enable VRS'}
            </Button>
          </Box>
        </Box>


        <Grid item xs={12}>
          <MaterialTable
            tableRef={tableRef}
            columns={tableColumnsRef.current}
            data={getContactsForLearner}
            options={{
              filtering: true,
              pageSize: defaultPageSize,
              thirdSortClick: false,
              search: false,
              toolbar: false,
            }}
          />
        </Grid>
      </Grid>

      <AddContactModal
        addContactModal={addContactModal}
        handleAddModalClose={handleAddModalClose}
        learners={learners}
        selectedLearner={learnerProfile}
        handleSearch={handleSearch}
        searchLearnerLoading={searchLearnerLoading}
        contact={contact}
        handleContactChange={handleContactChange}
        learnerListFetched={learnerListFetched}
        handleAddContact={handleAddContact}
        getRelationshipTypes={getRelationshipTypes}
      />

      <Snackbar id='add-contact-modal-snackbar' open={showAddContactSnackBar} autoHideDuration={snackbarAutoHideDuration} onClose={handleAddModalCloseSnackbar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleAddModalCloseSnackbar}
          severity={addContactSuccessSnackbar ? 'success' : 'error'}
        >
          {snackbarMessageSnackbar}
        </MuiAlert>
      </Snackbar>

      {showEnableVrsConfirmationModal && 
        <Modal
          id="enable-vrs-confirmation-modal"
          open={showEnableVrsConfirmationModal}
          onClose={() => {
            setShowEnableVrsConfirmationModal(false);
          }}
          continueButtonProps={{ disabled: disableActionButtons }}
          onContinue={() => {
            toggleVRSFlag();
            setShowEnableVrsConfirmationModal(false);
          }}
          actionBtnText="Confirm"
          cancelBtnText="Cancel"
          heading="Enable Video Relay Service"
          styles={{
            mainWrapperStyles: {
              height: 'auto',
            },
          }}
        >
          <Typography id="enable-vrs-confirmation-text" variant="body1">
            Are you sure you want to enable Video Relay Service (VRS) for {learnerProfile.learnerName}?
            <br />
            <br />
            This action will allow the resident to connect calls through a video interpreter who relays messages between sign language and spoken communication.
            <br />
            <br />
            <i>Note: This action requires the resident to use a device with the VRS application enabled to make VRS calls. Add the Serial Number in Communication settings to complete setup. The resident will not be able to use VRS until this is done.</i>
          </Typography>
        </Modal>
      }
    </>
  );
};

export default withStyles(styles)(LearnerProfileContainer);
